import React from 'react'
import Link from 'gatsby-link'
import {footer_text_big, footer_text_small, contact_us} from '../strings'

class FooterTitle extends React.Component{
    render(){
        var prefix_string_page = "";
        var contact_tag = "Contact us for free consulting and learn more about our offers: Consulting, Web & Apps Programming, digital marketing services (SEO, Google Ads & social media advertising and graphic & web design";
        var link_contact = "/contact";
        if(this.props.prefix_string_page === "bs"){
            prefix_string_page = "bs";
            link_contact = "/kontakt";
            contact_tag = "Kontaktirajte nas za besplatno savjetovanje i saznajte više o našim ponudama: konzalting, programiranje weba i aplikacija, usluge digitalnog marketinga (SEO, Google oglasi i oglašavanje društvenih medija) te grafički i web dizajn";
        }
        return(
            <div className="columns footer-title">
                <div className="column left is-6">
                <div className="title-box">
                        <h3 className="subtitle1">{footer_text_big}</h3>
                        <h4 className="subtitle2">{footer_text_small}</h4>
                    </div>

                     <Link to={prefix_string_page + link_contact} className="button black" title={contact_tag}>{contact_us} <span class="icon contact"></span></Link>
                </div>

                <div className="column right is-6">
                    <div className="contact-box">
                      {/*  <h2 className="title">Kontakt detalji:</h2>*/}
                        <a href="tel:+38761787346" className="line"><h4>+387 61 787 346</h4></a>
                        <a href="mailto:info@wellpromotion.ba" className="line email"><h4>info@wellpromotion.ba</h4></a>
                        <h4 className="line">Safeta Mujića 2, Sarajevo, 71000</h4>
                    </div>
                </div>
            </div>
        )
    }
}

//CONTENT OF FOOTER//
export default FooterTitle
